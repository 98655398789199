html, body{
  
  overflow:hidden;
}

.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 8px;
}

.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 6px !important;
}

.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 6px !important;
  /* color: rgba(0, 0, 0, 0.85); */
  font-size: 14px !important;
  line-height: 24px;
}

.ant-tabs-tab-prev-icon-target, .ant-tabs-tab-next-icon-target {
  color: white !important;
}

.loadCenter {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}

.shapes-list {
  /* bodyHeight - headerHeight - tabBarHeightAndMarginBottom - searchBoxHeight */
   height: calc(100vh - 64px - 56px - 32px - 170px);
   overflow-y: auto;
}

.resultPane {
  /* bodyHeight - headerHeight - tabBarHeightAndMarginBottom - searchBoxHeight */
   height: calc(100vh - 64px - 56px - 32px - 170px);
   /* overflow-y: auto; */
}

.esri-time-slider__slider {
  background-color: #f3f3f3;
  padding: 0 40px 0 40px;
}

.esri-time-slider__animation{
  display: none !important;
}
.esri-time-slider__time-extent{
  border-left: none !important;
}

.ant-layout-header{
  height:45px !important;
  line-height: 45px !important;
}
.ant-tabs-tab{
  color: white;
}

.chat-history{
  overflow-y: auto;
}

.chat-history::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.chat-history {
  -ms-overflow-style: none;
}
  
  #applicationDiv {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  #sliderContainer {
    flex: 0 0 80px;
    order: 3;
    display: flex;
    flex-flow: row;
    padding: 0;
  }

  #infoDiv {
    flex: 0 0 30;
    order: 1;
    display: flex;
    flex-wrap: wrap;
    flex-flow: row;
    padding: 0 12px;
    height:90px;
  }

  #titleHead{
    padding-left:10px;
    width:30%;
    flex-wrap: wrap;
    display:inline-flex;
  }
  
  #dropDownStuff{
    padding-top: 3px;
  }
  #viewMode{
    width: 100%;
    display: inline-flex;
    padding-bottom: 5px;
  }
  #viewModeTitle{
    padding-right:5px;
    padding-top: 3px;
    display: inline-flex;

  }
  #viewModeHelper{
    padding-left:5px;
    padding-top: 3px;
    
  }

  #viewDiv {
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
    order: 2;
  }

  #timeSlider {
    width:100%;
    padding-top: 10px;
  }
  #titleDiv {
    text-align: left;
    padding-top: 4px;
    margin-bottom: 0px;
    width: 100%;
  }
  #rangeDiv{
    text-align: left;
    padding-top: 2px;
    width: 100%;
  }
  #casesContainer,#deathContainer{
    text-align: left;
    padding-top: 4px;
    text-align: left;
    width:50%;
    display: inline-block;
  }
  #casesTotal,#newCases,#deathTotal,#newDeath{
    width:50%;
    display: inline-block;
    text-align: center;
  }
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0rem;
}

.esri-slider{
  background-color: white !important;
}
.esri-time-slider__slider{
  background-color: white !important;
}
.esri-time-slider__row{
  height: 60px !important;
}
.esri-time-slider__slider {
  background-color: #ffffff !important;
  padding: 0 10px 0 10px;
}

.esri-time-slider__slider .esri-slider {
  margin-top: -19px;
  background-color: #ffffff !important;
}
.esri-time-slider__layout--compact{
  height:60px !important;
}
.ant-tabs-tab-arrow-show .ant-tabs-tab-next-icon-target .ant-tabs-tab-next .ant-tabs-tab-prev{
  color: white !important;
}

.ant-list-item-meta-description {
  color:white !important;
}
.ant-list-vertical .ant-list-item-meta-title{
  color: white !important;
}
.ant-list-item-meta-title > a{
  color: white !important;
}

.ant-list-item-action > li{
  color: white !important;
}
.ant-pagination{
  color:white !important;
}
.ant-pagination input{
  color:black !important;
}
  /* #deathTotal{
    padding-top: 5px;
    width: 50%;
    vertical-align: middle;
    padding-right:70px;
    text-align: left;
    display: inline;
    
  }
  #newDeath{
    width:100%;
    padding-top: 18px;
    vertical-align: middle;
    padding-right:35px;
    text-align: center;
    display: inline-block;

  } */

  .dropdown-toggle{
    background-color: transparent;
    border-color:transparent;
    box-shadow:none;
  }

  


  /* .dropdown-toggle:hover{
    background-color: transparent;
    border-color:transparent;
    box-shadow:none;
  } */

  /* .show>.btn-secondary.dropdown-toggle:focus {
    background-color: transparent;
    border-color:transparent;
    box-shadow:none;
  }
  .btn-secondary:focus {
    background-color: transparent;
    border-color:transparent;
    box-shadow:none;
  } */

  .dropdown-toggle::after {
    display: none;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

  /* #infoDiv {
    height: relative;
    padding: 10px;
    width: 100%;
  } */
  #dashdiv{
    overflow: hidden;
    white-space: nowrap;
  }
  #infoDiv span {
    color: #f9c653;
    font-size: 20pt;
    font-weight: bolder;
  }
  /* .chat-messages{
    max-height: 300px;
  } */
  #chat-panel{
    max-height: 50vh;
  }
  .esri-popup__main-container.esri-widget {
    width: 25%;
  }

  @media (min-width: 992px){
    
    
  }

  @media only screen and (max-width: 992px) {
    #infoDiv span {
      font-size: 16pt;
    }
    .modal-lg, .modal-xl {
      max-width: 70vw;
    }
  }

  @media only screen and (max-width: 790px) {
    #infoDiv span {
      font-size: 12pt;
    }
    
  }

  #components-layout-demo-custom-trigger .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  #components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
  }
  
  #components-layout-demo-custom-trigger .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }
  
  .site-layout .site-layout-background {
    background: #fff;
  }

  .trigger{
    padding-left:10px;
    height: 24px;
    width: 24px;
  }